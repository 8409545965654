<template>
  <v-app class="wmyHomeApp">
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/other/wmyH5VRpanoramaWebsite_banner.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex mt-8" style="width:100%">
          <v-card color="transparent" flat style="width:100%">
            <div class="d-flex flex-no-wrap justify-space-between" style="width:100%">
              <div style="width:100%">
                <v-card-title class="white--text justify-center py-2 text-align-center font-size-h5banner-title">
                  VR数字空间体验<br>胜似“身临其境”
                </v-card-title>
                <v-card-title class="white--text font-size-h5banner-tip justify-center py-1 text-align-center px-5">
                    产品、用户零距离，虚拟空间互动引领者
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- VR全景系统 -->
    <div class="width-per-100 pb-3 px-4 mt-10 position-r">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">{{data.module.title}}</v-card-title>
      <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">{{data.module.subtitle}}</v-card-text>
      <v-tabs v-model="tab" background-color="transparent" color="#2181EA" dark class="xs-tab">
        <v-tab v-for="(v, k) in data.module.listArr" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.module.listArr.length - 1 ? 'mr-5':'']"
               style="min-width: unset" @click="clickItem(v.id)">
          <b class="color-666 font-size-15 letter-spacing line-height-20">{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-1 position-r" v-model="tab">
        <v-tab-item v-for="(v, k) in data.module.listArr" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.img" width="100%" contain style="border-radius:15px;min-height:220px" id="imgWH">
              <canvas v-show="v.id == 4" :class="'aroundImgPreview'+v.id" @mousedown="handleCanvasDown" @mousemove="handleCanvasMove" @mouseup="handleCanvasUp"></canvas>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-2 font-size-13 font-weight-bold">{{v.lunTitle}}</v-card-title>
            <v-card-text class="pa-0 font-size-12 color-555 line-height-21" style="margin-top: 6px;" v-text="v.tip" />
            <div class="font-size-13 color-666" style="margin-top: 10px;">
              <v-card-text class="pa-0 line-height-20" v-for="(i, j) in v.text" :key="j" v-text="i" />
            </div>
          </v-card>
        </v-tab-item>
        <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
        <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
      </v-tabs-items>
    </div>
    <!-- VR全景拍摄 -->
    <div class="width-per-100 pb-3 px-4 mt-10">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">{{data.module1.title}}</v-card-title>
      <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">{{data.module1.subtitle}}</v-card-text>
      <v-tabs v-model="tab1" background-color="transparent" color="#2181EA" dark class="xs-tab">
        <v-tab v-for="(v, k) in data.module1.listArr" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.module1.listArr.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-666 font-size-14 letter-spacing line-height-20">{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-1 position-r" v-model="tab1" style="background:transparent">
        <v-tab-item v-for="(v, k) in data.module1.listArr" :key="k" :transition="false">
            <v-row no-gutters justify='space-between'>
                <v-col :cols="v.itemList.length >1?'6':'12'" class="d-flex justify-center pa-1" v-for="(col,colIndex) in v.itemList" :key="colIndex">
                    <v-card flat color="transparent" v-show="k === 0">
                        <div class="d-flex justify-center">
                            <v-img :src="col.src" contain width="100%"></v-img>
                        </div>
                        <v-card-title class="pa-0 font-size-15 line-height-22 justify-center text-align-center font-weight-bold color-333 mt-2">
                            {{col.title}}
                        </v-card-title>
                        <v-card-text class="pa-0 mt-1 ml-1 text-align-center">
                            <span class="font-size-12 color-666 font-weight-300 line-height-17">{{col.content}}</span>
                        </v-card-text>
                    </v-card>
                    <v-card flat v-show="k === 1">
                        <div class="img_title" justify="center">
                            <v-img :src="col.src" class="align-center" width="100%"></v-img>
                        </div>
                    </v-card>
                    <v-card flat v-show="k === 2">
                        <div class="img_title" justify="center">
                            <v-img :src="col.src" class="align-center rounded-lg" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                                <div class="white--text font-size-16 pl-6 pr-6  font-weight-bold text-align-center">{{col.title}}</div>
                            </v-img>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-tab-item>
        <div class="tab-btn-prev" @click.stop="handleTabBtn1('prev')"></div>
        <div class="tab-btn-next" @click.stop="handleTabBtn1('next')"></div>
      </v-tabs-items>
    </div>
  </v-app>
</template>

<script>
import lavData from '@/static/lavData.js'
import FramePlayer from 'frame-player';
export default {
  name: 'solutionAR',
  components: {
  },
  data() {
    return {
      tab:0,
      tab1:0,
      data:lavData.h5VRpanoramaWebsite,
      imgLoad: false,
      framePlayer: null, //360物体预览对象
      around_play: false, //360物体播放控制
      around_scale: 100,
      sanliuleng: null,
      imgArr: [
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-01.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-02.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-03.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-04.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-05.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-06.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-07.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-08.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-09.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-10.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-11.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-12.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-13.png',
        'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-14.png',
      ]
    }
  },
  watch: {},
  mounted(){},
  created() {
    this.newFramePlayer();
  },
  methods: {
    handleLoad() {
      this.imgLoad = true;
    },
    handleTabBtn: function (type){
      const maxTab = this.data.module.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    },
    handleTabBtn1: function (type){
      const maxTab = this.data.module1.listArr - 1;
      if(type === "prev"){
        if(this.tab1 === 0){
          this.tab1 = maxTab;
        }else {
          this.tab1--;
        }
      }else if(type === "next"){
        if(this.tab1 === maxTab){
          this.tab1 = 0;
        }else {
          this.tab1++;
        }
      }
    },
    aroundPlay(val) {
      // this.newFramePlayer();
      if(this.framePlayer){
        if(val){
          this.framePlayer.play();
        }else {
          this.framePlayer.stop();
        }
      }
    },
    clickItem(id) {
      this.sanliuleng = id
      let _this = this
      setTimeout(()=>{
        _this.newFramePlayer();
      },100)
    },
    newFramePlayer() {
      if(this.sanliuleng != 4) return
      this.clearFramePlayer();
      this.$nextTick(()=>{
        this.framePlayer = new FramePlayer({
          canvas: ".aroundImgPreview4",
          width: 400,
          height: 260,
          images: this.imgArr,
          fps: 5,
          loop: -1,
          transparent: false,
          autoplay: false
        });
      console.log(this.framePlayer)
        this.framePlayer.on("ready",()=>{
          // this.around_play = true;
          // this.framePlayer.play(0);
          this.framePlayer.play(0);
          console.log(this.framePlayer)
        });
        this.framePlayer.on("update",(options)=>{
          this.frame = options.frame;
        });
      })
    },
    clearFramePlayer() {
      this.around_play = false;
      if(this.framePlayer){
        this.framePlayer.off("ready");
        this.framePlayer.off("update");
        this.framePlayer.stop();
        this.framePlayer = null;
      }
    },
    handleCanvasDown(e) {
      this.canvasDown = true;
      this.coordinate.x = e.clientX;
      this.coordinate.y = e.clientY;
    },
    handleCanvasMove(e) {
      if(this.canvasDown){
        if(e.clientX - this.coordinate.x > 5){
          if(this.frame === this.imgArr.length - 1){
            this.frame = 0;
          }else {
            this.frame++;
          }
          this.coordinate.x = e.clientX;
        }else if(this.coordinate.x - e.clientX > 5){
          if(this.frame === 0){
            this.frame = this.imgArr.length - 1;
          }else {
            this.frame--;
          }
          this.coordinate.x = e.clientX;
        }
        this.coordinate.y = e.clientY;
      }
    },
    handleCanvasUp (e){
      console.log(e)
      this.canvasDown = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
}
.v-tab--active{
    b{
        color: #2181EA !important;
    }
}
.v-window{
  overflow: unset;
}
.tab-btn-prev{
  left:-19px;
}
.tab-btn-next{
  right:-19px
}
.wmyHomeApp{
  background: #F5F8FE;
}
#aroundImgPreview, .aroundImgPreview4 {
  width: 100%;
  height: 100% !important;
  border-radius: 30px;
  position: absolute;
  z-index: 1;
}
</style>
